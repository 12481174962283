@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 1650px) {
  .login-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
  }
  /* dashboard */
  .dashboard-popup-inner {
    min-width: 75vw;
  }
  .top-employee-count {
    padding: 18px 25px;
  }
  .dashboard-card-label {
    font-size: 16px;
  }
  .dashboard-count-wrap .dashboard-card {
    padding: 15px;
  }
  .dashboard-card-text {
    padding-right: 0;
  }
  .dashboard-top-employees {
    max-height: 37vh;
  }
  .home-banner-section {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 126px;
  }
  .home-get-started-inner {
    padding: 45px 25px;
  }
  .home-get-started-title {
    font-size: 44px;
    line-height: 1.2;
  }
  .home-get-started-tie-text {
    font-size: 44px;
    margin-right: 35px;
  }
  .home-get-started-button {
    padding: 24px 20px;
  }
  .dashboard-navbar-search .form-control {
    width: 28vw;
  }
  .appraisal-form-inner .form-group {margin-top: 14px;padding: 7px 12px;}
  .vehicle-photos-upload {height: 94px;}
  .login-inner.add-new-appraisal-inner {padding: 22px;}
  .appraisal-form-inner .col-form-label {padding: 0 5px 0 0;margin-right: 5px;}
  .appraisal-form-inner .col-form-label,.appraisal-form-inner .form-control,.appraisal-form-inner .form-select{font-size: 14px;}
  .scanner-open-btn {right: -14px;font-size: 18px;}
  .vehicle-details.without-title {margin-top: 38px;}
}

@media only screen and (max-width: 1460px) {
  .register-hero-text {
    font-size: 86px;
    max-width: 560px;
  }
  /* dashboard */
  .page-title {
    font-size: 32px;
  }
  .page-sub-title {
    font-size: 18px;
  }
  .dashboard-inner {
    padding: 20px;
  }
  .dashboard-card-count {
    font-size: 21px;
    margin-bottom: 5px;
  }
  .dashboard-card-icon {
    flex: 0 0 30px;
    max-width: 30px;
    height: 30px;
  }
  .dashboard-card-label {
    font-size: 14px;
  }
  .dashboard-card-select .form-select,
  .dashboard-count-filter .form-select {
    padding: 10px 34px 10px 14px;
    font-size: 14px;
  }
  .dashboard-card {
    padding: 20px 15px;
  }
  .total-appraisals-count {
    font-size: 38px;
  }
  .login-image .total-appraisals-count{font-size: 38px;}
  .total-appraisals-text {
    font-size: 26px;
  }
  .total-appraisals-button-wrap {
    margin-top: 20px;
  }
  .deal-user-image img {
    height: 49px;
    width: 49px;
    margin-right: 10px;
  }
  .total-appraisals-added-card p{font-size: 16px;}

  .dashboard-featured .dashboard-card.total-appraisals-added-card{padding-bottom: 74px;}
  .dashboard-featured .total-appraisals-button-wrap{margin-top: 0px; position: absolute; bottom: 24px;right: 24px;}
  .dashboard-card .table tbody td {
    padding: 12px 14px;
  }
  .card-title {
    font-size: 20px;
  }
  .dashboard-navbar-search .form-control {
    width: 22vw;
  }
  .mb-24 {
    margin-bottom: 18px;
  }
  .dashboard-links li {
    margin-right: 20px;
  }
  .login-inner{padding: 34px;}
  .login-section{padding: 34px 0;}
  .login-image .dashboard-card{min-width: 200px;}
  .login-image .total-appraisals-added-card{width: 250px;padding: 20px 15px;}
  .total-appraisals-text{font-size: 22px;}
  .login-image .dashboard-card.is-total-won{left: 125px;top: 60px;}
  .login-image .dashboard-card.is-total-appraisals{left: 210px;}
  .offer-details-inner .form-group .form-control, .offer-details-inner .form-group .form-select {padding: 8px 12px;}
  .home-step.step-2 .home-steps-image {margin-right: -50px;}
  .header-links {padding-right: 50px;}
  .header {padding: 16px 15px;}
  .sec-subtitle{font-size: 24px;}
  .sec-title {font-size: 50px;line-height: 1.2;}
  .home-steps-con .sec-title {font-size: 42px;}
  .home-step {padding-bottom: 60px;}
  .home-get-started {padding-left: 30px;padding-right: 30px;}
  .home-get-started-inner {padding: 35px 25px;}
  .home-get-started-title {font-size: 44px;}
  .home-get-started-button {font-size: 22px;padding: 27px 40px;}
  .home-get-started-tie-text{font-size: 32px;}
  .vehicle-details.without-title {margin-top: 34px;}
}

@media only screen and (max-width: 1366px) {
  /* dashboard */
  .top-employee-count-count {
    font-size: 32px;
  }
  .dashboard-popup-title {
    font-size: 20px;
  }
  .dashboard-latest-apraisals .table {
    min-width: 1300px;
  }
  .dashboard-card .table tbody td {
    font-size: 14px;
  }
  .btn {
    font-size: 14px;
    padding: 13px 20px 10px;
  }
  .btn-outline-grey {
    padding: 8px 34px 6px 14px;
  }
  .appraisalcol{margin-bottom: 20px;}
  .show-earningsMain{margin-top: 80px;}
  .purchase-section .login-section{height: auto;padding: 50px 0;}
  .login-title{font-size: 28px;margin-bottom: 20px;}
  .login-text{margin-bottom: 25px;}
  .login-form-inner .form-group,.login-form-inner .form-check{margin-bottom: 20px;}
  .login-image .dashboard-card.is-total-won{left: 110px;}
  .login-image .dashboard-card.is-total-appraisals{left: 180px;}
}

@media only screen and (max-width: 1280px) {
  /* dashboard */
  .dashboard-navbar-logo img {
    width: 220px;
  }
  .deal-user-image img {
    height: 39px;
    width: 39px;
  }
  .dashboard-card .table tbody td {
    padding: 9px 12px;
  }
  .dashboard-top-employees .table {
    min-width: 570px;
  }
  .dashboard-top-employees {
    max-height: 34vh;
  }
  .total-appraisals-button-wrap {
    margin-top: 29px;
  }
  .dashboard-navbar-search .form-control {
    width: 23vw;
  }

  .dashboard-links li {
    margin-right: 0;
  }

  .dashboard-button-box {
    margin-right: 10px;
  }

}
@media only screen and (max-width: 1199px) {
  .login-inner {
    padding: 28px;
  }
  .login-section .login-inner{padding-bottom: 28px;}
  .login-title {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .login-text {
    margin-bottom: 35px;
  }
  .login-image {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  /* dashboard */
  .dashboard-navbar-logo img {
    width: 60px;
  }

  .dashboard-navbar-search .form-control {
    width: 34vw;
  }
  .dashboard-count-wrap .dashboard-card {
    height: unset;
    margin-bottom: 15px;
  }
  .dashboard-count-wrap {
    margin-bottom: 5px;
  }
  
  .dashboard-featured .dashboard-card {
    height: unset;
  }
  .deal-user-image img {
    height: 59px;
    width: 59px;
    margin-right: 16px;
  }
  .dashboard-featured .dashboard-card.total-appraisals-added-card {
    height: 100%;
  }
  .total-appraisals-button-wrap {
    margin-top: 0;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .total-appraisals-added-card {
    padding-bottom: 64px;
  }
  .top-employee-count-count {
    font-size: 25px;
  }
  .top-employee-user h2 {
    font-size: 22px;
  }
  .top-employee-user-image img {
    width: 65px;
    height: 65px;
  }
  .appraisal-head-wrap .page-title{font-size: 22px;}
  .appraisal-head-wrap .btn-icon{width: 35px;height: 35px;font-size: 16px;}
  .appraisal-tabs .nav-pills .nav-link{padding: 13px 20px;}
  .dashboard-card{padding: 15px;}
  .login-image .dashboard-card{padding: 15px;}
  .card-title{font-size: 18px;}
  .appraisal-form-inner .form-group{margin-top: 15px;padding: 9px 18px;}
  .appraisal-form-inner .form-check {margin-top: 15px;}
  .manager-info h4{font-size: 18px;}
  .manager-image img{width: 45px;height: 45px;}
  .offer-note-detail p{font-size: 16px;}
  .history-date{margin: 20px 0;}
  .history-date .date-Title{font-size: 16px;}
  .history-notes-inner .offer-note-info p{font-size: 16px;margin-bottom: 3px;}
  .dashboard-backdrop.show, .dashboard-popup.show{padding: 50px 20px;}
  .get-help-popup .dashboard-popup-inner{padding: 30px 30px;}
  .get-help-Title{font-size: 26px;margin-bottom: 30px;}
  .question-search{margin-bottom: 30px;}
  .helpyouboxMain{margin-bottom: 50px;}
  .secHead{margin-bottom: 30px;}
  .secHead .secTitle{font-size: 26px;}
  .faq-accordionMian .accordion-button{font-size: 18px;}
  .secHead .secTitle.size24{font-size: 20px;}
  .dashboardml ~ .dashboardml{margin-left: 6px;}
  .dashboard-navbar-date-filter .date-filter{font-size: 14px;}
  .dashboard-navbar-date-filter .date-filter .icon{font-size: 12px;}
  .dashboard-navbar-filter .filter{padding: 7px 13px;}
  .dashboard-navbar-filter .filter .icon{font-size: 14px;}
  .dashboard-navbar-month{min-width: auto;}
  .notification{max-width: 100%;padding: 20px;padding-top: 30px;}
  .notification.usernotification{max-width: 100%;}
  .notification-Title{margin-bottom: 20px;}
  .edit-profile .manager-image img{width: 45px;height: 45px;}
  .edit-profile .manager-info h4{font-size: 18px;}
  .notification.edit-profile{padding: 25px;}
  .offer-note-image .icon{font-size: 16px;}
  .dashboard-notification .offer-note-image{width: 36px;height: 36px;}
  .dashboard-notification .offer-note-detail p{font-size: 14px;}
  .user-reset-password-section{padding: 38px 0;}
  .no-appraisalMain{padding-top: 50px;}
  .add-new-appraisal-inner .secHead{margin-bottom: 30px;}
  .add-new-appraisal-inner .vehicle-photos{padding: 10px 0;}
  .new-appraisal-card-submit{margin-top: 20px;}
  .new-appraisal-card-submit .form-privacy-policy{margin-top: 30px;}
  .login-inner.add-new-appraisal-inner{padding: 28px;}
  .appraisal-head-wrap.notifications-head-wrap .page-title{font-size: 22px;}
  .dashboard-popup-inner.appraisal-listing-popup{width: 90%;min-width: 90%;}
  .appraisal-listing-popup .dashboard-popup-close{width: 28px;height: 28px;font-size: 12px;top: -10px;right: -10px;line-height: 28px;}
  .carlistslide .slick-prev{left: 20px;}
  .carlistslide .slick-next{right: 20px;}
  .carlistslide .slick-prev, .carlistslide .slick-next{width: 35px;height: 35px;}
  .carlistslide .slick-prev:before, .carlistslide .slick-next:before{line-height: 35px;}
  .dashboard-sidebar{padding: 20px 0 0 0;}
  .dashboard-sidebar li{margin-bottom: 5px;}
  .show-earningsMain{max-width: 100%;margin-top: 0;padding: 20px 20px;}
  .earnings-head .earnings-Title{font-size: 20px;padding-left: 30px;}
  .earnings-head .earnings-Title .icon{font-size: 18px;}
  .show-earningsMain .switch-button .switch{width: 42px;height: 23px;}
  .show-earningsMain .switch-button .switch::before{top: 3px;right: 3px;width: 18px;height: 18px;}
  .show-earningsMain .switch-button input:checked + .switch::before{right: 21px;}
  .my-edit-profile{margin-top: 50px;}
  .register-hero-text{font-size: 62px;max-width: 405px;}
  .form-privacy-policy br{display: none;}
  .purchase-section.login-section .login-inner{margin-top: 0;margin-bottom: 30px;}
  .one-purchaseMian .usdprice{font-size: 24px;}
  .one-purchaseMian .unlimiteduser{font-size: 16px;}
  .purchase-section .login-title{margin-bottom: 20px;}
  .successful-section .login-title{margin-bottom: 20px;}
  .successfulpayMian{padding: 15px 20px;}
  .successfulTitle{font-size: 20px;}
  .successfulpayMian p{font-size: 14px;}
  .total-appraisals-added-card{padding-bottom: 63px;}
  .dashboard-card-icon{font-size: 16px;}
  .dashboard-card-text{padding-left: 5px;}
  .dashboard-card-count{font-size: 18px;}
  .login-image .dashboard-card{min-width: 175px;}
  .login-section .total-appraisals-added-card,.home-banner-section .total-appraisals-added-card{padding-bottom: 20px;}
  .login-section .total-appraisals-button-wrap,.home-banner-section .total-appraisals-button-wrap{position: static;bottom: inherit;right: inherit;}
  .login-image .total-appraisals-count{font-size: 28px;}
  .login-image .total-appraisals-text{font-size: 18px;}
  .login-image .total-appraisals-added-card{right: 0;}
  .login-image .dashboard-card.is-total-won{left: 50px;top: 50px;}
  .login-image .dashboard-card.is-total-appraisals{left: 80px;top: -50px;}
  .login-image .total-appraisals-added-card{width: 200px; top: -50px;}
  .dashboard-featured .dashboard-card.total-appraisals-added-card{padding-bottom: 63px;}
/*.dashboard-featured .total-appraisals-button-wrap{margin-top: 0; position: absolute; bottom: 20px;right: 20px;}*/
.settings-notificationbox .table-responsive{overflow: auto;
    height: 100%;}
  .settings-notificationbox .table{min-width: 570px;}
  .label-text.w-50{width: 50% !important;}
  .sec-title {font-size: 44px;}
  .sec-subtitle {font-size: 22px;}
  .home-banner-buttons .btn {padding: 24px 35px 21px;}
  .home-steps-con{max-width: 90%;}
  .home-steps-con .sec-title {font-size: 36px;}
  .home-get-started-title {font-size: 36px;line-height: 1.2;}
  .home-get-started-tie-text {font-size: 36px;}
  .home-get-started-tie {width: 100%;justify-content: space-between;}
  .home-get-started-call-us-sec{width: 100%; padding-top: 20px;}
  .home-get-started-call-us-sec .row{width: 100%;}
  .vehicle-details.without-title {margin-top: 0;}
}
@media only screen and (max-width: 991px) {
  .login-section:not(.with-bg) {
    height: unset !important;
  }
  .login-upper-section {
    padding: 50px 0;
  }
  .login-down-section {
    padding-bottom: 50px;
  }
  .login-section.with-bg .login-upper-section {
    padding: 40px 0;
  }
  /* dashboard */
  .dashboard-latest-apraisals .table {
    min-width: 1200px;
  }
  .dashboard-profile-dropdown span {
    display: none;
  }
  .dashboard-button-box .btn-pink {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50%;
    z-index: 9;
    border-radius: 0;
  }

  .dashboard-button-box .btn-pink + .btn-pink {
    right:-2px;    
    left: inherit;
  }

  .dashboard-navbar {
    padding: 12px 23px;
  }
  .dashboard-navbar-search .form-control {
    width: 48vw;
  }
  .deal-user-image img {
    height: 49px;
    width: 49px;
    margin-right: 10px;
  }
  .dashboard-popup-inner {
    min-width: unset;
    width: 90%;
  }
  .top-employee-popup .dashboard-card .table {
    min-width: 770px;
  }
  .top-employee-count-icon {
    width: 30px;
    height: 30px;
    font-size: 16px;
    margin-right: 10px;
  }
  .top-employee-count {
    padding: 12px;
  }
  .top-employee-popup-header {
    padding-left: 14px;
    padding-right: 14px;
  }
  .menuIcon {
    display: inline-block;
    vertical-align: middle;
  }
  .dashboard-sidebar {
    position: fixed;
    bottom: 0;
    top: 0;
    left: -100%;
    z-index: 9;
    flex: unset;
    max-width: unset;
    width: 220px;
    z-index: 999;
    overflow: auto;
    background-color: #ffffff;
    height: 100%;
    padding: 0;
    transition: left 0.4s ease-in-out;
  }
  .dashboard-sidebar.show {
    left: 0;
  }
  .menuIcon.show span {
    background: transparent;
  }
  .menuIcon.show span:before {
    transform: rotate(45deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
  }
  .menuIcon.show span:after {
    transform: rotate(-45deg);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
  }
  .dashboard-wrapper {
    padding-bottom: 46px;
  }
  .mbmargin{margin-bottom: 15px;}
  .dashboard-latest-appraisals .dashboard-navbar-search .form-control{width: 200px;}
  .get-help-popup .dashboard-popup-inner{width: 100%;}
  .dashboard-notification .offer-note-detail{max-width: 100%;padding-right: 0;}
  .user-reset-password-section .login-upper-section{height: inherit;padding: 0;}
  .login-section{height: 100vh;padding: 0;}
  .purchase-section.login-section .login-inner{margin-top: 50px;margin-bottom: 30px;}
  .purchase-section .login-copyright{margin-bottom: 0;}
  .login-section .login-inner{margin-bottom: 90px;}
  @keyframes rotateme{0%{transform:rotate(0deg)}to{transform:rotate(-1turn)}}@keyframes float-bob{0%{transform:translateY(0)}50%{transform:translateY(-10px)}to{transform:translateY(0)}}@keyframes float-bob-up{0%{transform:translateY(-15px)}50%{transform:translateY(0)}to{transform:translateY(-15px)}}
.notificationbox{left: inherit;right: 0;transform: none;}
/*---date-range---*/
.react-daterange-picker__button svg {width: 13px;height: 13px;}
.react-daterange-picker__inputGroup__input{font-size: 14px;}
/*---date-range---*/
.dashboard-button-box {margin-right: 7px;}
.dashboard-inner.is-sticky .appraisal-head-wrap.edit-page {left: 0;}
.dashboard-navbar-logo img {width: 60px;}
.dashboard-profile-dropdown i{display: none;}
.dashboard-profile-dropdown-button img{margin-right: 0;}
.dashboard-profile-dropdown-button{padding: 0;}
.dashboard-navbar .dashboard-count-filter .form-select{max-width: 150px;margin-right: 7px;}
.home-step.step-2 .home-steps-image {margin-right: -20px;}
.header .btn {padding: 17px 32px 14px;}
.home-banner-section {padding: 100px 15px 0;}
.home-banner-con{margin-top: 70px;text-align: center;}
.home-steps-image{margin-bottom: 50px;}
.home-step.step-2 .home-steps-image{margin-right: 0;}
.home-step.step-3{padding-bottom: 10px;}
.home-step.step-3 .home-steps-con{margin-bottom: 50px;}
.home-get-started-inner {padding: 45px 15px;}
.home-get-started-tie-text {font-size: 32px;}
.home-get-started-button {padding: 22px 35px;}

.appraisal-tabs .nav{display: flex;flex-wrap: nowrap;overflow: auto;align-items: center;margin-bottom: 25px;}
.appraisal-tabs .nav-pills .nav-link{min-width: 120px;}
}

@media only screen and (max-width: 767px) {
  .login-section.with-bg {
    height: unset !important;
  }
  .dashboard-inner{padding: 20px 15px;}
  p{font-size: 14px;}
  
  .w-442{width: 100%;}
  .history-notes-inner .form-group ul li{margin-bottom: 15px;}
  .pagination .nav-links li a{width: 32px;height: 32px;line-height: 32px;font-size: 12px;margin: 0 2px;}
  .page-numbers.prev, .page-numbers.next{font-size: 14px;}
  .page-numbers.prev{margin-right: 8px;}
  .page-numbers.next{margin-left: 8px;}
  .dashboard-navbar-date-filter .date-filter .icon{font-size: 12px;margin-left: 0px;}
  .dashboard-latest-appraisals .dashboard-navbar-search .form-control{width: 180px;font-size: 14px;}
  .get-help-Title{font-size: 20px;}
  .question-search{width: 100%;}
  .helpyouboxrow{margin: 0 -5px;}
  .helpyouboxcol{padding: 0 5px;}
  .secHead .secTitle{font-size: 20px;}
  .secHead p{font-size: 14px;}
  .secHead{max-width: 100%;}
  .faq-accordionMian .accordion-button{font-size: 16px;padding: 15px;padding-right: 45px;}
  .faq-accordionMian .accordion-button::before{right: 15px;}
  .faq-accordionMian .accordion-button::after{right: 23px;}
  .faq-accordionMian .accordion-body{padding: 15px;padding-top: 0;}
  .get-help-popup .dashboard-popup-inner{padding: 30px 15px;}
  .contactMian .secHead{margin-top: 25px;margin-bottom: 0;}
  .contactinfoMian .secHead{max-width: 100%;}
  .contact-inner{padding: 30px 20px;}
  .login-form-inner .form-group,.login-form-inner .form-check{margin-bottom: 12px;}
  .contactinfoMian .secHead .secTitle{margin-bottom: 10px;}
  .contactinfoMian .secHead{margin-bottom: 20px;}
  .contactdetails{padding-top: 30px;}
  .continfo ul li{margin-bottom: 18px;}
  .continfo{margin-bottom: 25px;}
  .socialMeadia a{margin: 0 6px;}
  .dashboard-navbar-filter .filter{padding: 5px 13px;}
  .dashboardml ~ .dashboardml{margin-left: 4px;}
  .dashboard-navbar-filter{min-width: auto;}
  .dashboard-export .btn{padding: 9px 14px 8px;}
  .dashboard-export .dashboard-export .btn{font-size: 0;}
  .dashboard-navbar-month.dashboardml{margin-left: 0;}
  .notification{padding: 15px;padding-top: 20px;}
  .notification-Title{font-size: 20px;}
  .mb-34, .mx-34{margin-bottom: 24px;}
  .dashboard-notification .table thead th, .dashboard-notification .table tbody td{padding: 15px 15px;}
  .dashboard-switch-button .switch-buttonmain{padding: 0px;border: none;}
  .switch-button-text{display: none;}
  .switch-button{margin-left: 0;}
  .notification.edit-profile{padding: 20px 15px;}
  .edit-profile .manager-details-inner{margin-bottom: 10px;}
  .edit-profile .manager-info h4{font-size: 16px;}
  .page-title{font-size: 28px;}
  .page-sub-title{font-size: 16px;}
  .login-title{font-size: 22px;}
  .login-text{margin-bottom: 25px;}
  .inputField{font-size: 14px;}
  .user-reset-password-section .login-inner{margin-bottom: 20px;}
  .user-reset-password-section .login-down-section{padding-bottom: 0;}
  .no-appraisalMain{padding-top: 30px;}
  .no-appraisalimg{max-width: 150px;margin-bottom: 15px;}
  .carlistslide .slick-dots{bottom: 20px;}
  .show-earningsMain{padding: 15px;margin-bottom: 15px;}
  .my-edit-profile{margin-top: 0;}
  .manager-info span{font-size: 14px;}
  .btn ~ .btn{margin-left: 6px;}
  .login-down-section{padding-bottom: 30px;}
  .purchase-section.login-section .login-inner{margin-top: 30px;}
  .purchase-section .login-form{margin-top: 20px;}
  .one-purchaseMian{padding: 0;}
  .login-form-inner .form-label{font-size: 14px;}
  .login-form-inner .btn{margin-bottom: 20px;}
  .purchase-section.login-section .login-inner{padding-bottom: 28px;}
  .login-tabs li{font-size: 14px;}
  .one-purchaseMian .usdprice{font-size: 20px;}
  .one-purchaseMian .unlimiteduser{font-size: 14px;}
  .one-purchaseMian .purchaseTitle{margin-bottom: 16px;}
  .one-purchaseMian .purchase-dec{margin-top: 16px;}
  .login-copyright span{font-size: 14px;}
  .upload-button .icon{font-size: 14px;}
  .upload-button{min-width: auto;font-size: 14px;}
  .offer-note-input .form-control{min-height: 45px;font-size: 14px;}
  .offer-note-input .btn-icon{width: 45px;height: 45px;}
  .offer-note-detail p{font-size: 14px;}
  /*.dashboard-notification .offer-note-head{display: block;}
  .offer-note-info{display: block;margin-bottom: 8px;}
  .noted-action-buttons{margin-bottom: 15px;}*/
  .offer-detailcol{margin-bottom: 15px;}
  .offer-detailcol:last-child{margin-bottom: 0px;}
  .offer-notes .appraisal-form-head{margin-top: 15px;margin-bottom: 15px;}
  .offer-notecol{width: 100%;}
  .offer-note-image{margin-bottom: 15px;}
  .offer-note{position: relative;}
  .noted-action-buttons{position: absolute;top: 15px;right: 15px;}
  .offer-note-info{width: 100%;max-width: unset;}
  .offer-note-detail p{font-size: 14px;}
  .offer-note-input .form-control{font-size: 14px;}
  .dashboard-notification .offer-note-info{margin-bottom: 15px;}
  .dashboard-card-count{font-size: 16px;}
  .login-image .total-appraisals-count{font-size: 22px;}
  .login-image .total-appraisals-text{font-size: 16px;}
  .login-image .total-appraisals-added-card{width: 180px;}
  .notificationbox{padding: 15px;width: 235px;}
  .notification-close{top: 7px;right: 7px;}
  .notifiTitle{font-size: 16px;}
  .notifibox{margin-top: 10px;}
  .serchbox-dropdown{padding: 12px;}
  .searchboxlist ul li{margin-bottom: 8px;padding-bottom: 8px;}
.vehicle-image img{width: 36px;height: 36px;}
.vehicle-Head .vehicle-Title{font-size: 15px;}
.vehicle-Head p{font-size: 13px;}
.settings-notification.notification .input-group .form-group{margin-right: 0;}
.label-text{margin-top: 10px;}
.label-text.w-50{width: 100% !important;}
.offer-details-inner .form-group .deal_amount{max-width: unset;}
.offer-details-inner .form-group .form-control, .offer-details-inner .form-group .form-select {padding: 8px 15px;}
.home-banner-section {padding: 100px 0 0;}
.header {padding: 16px 10px;}
.home-banner-con {margin-top: 60px;}
.sec-title {font-size: 42px;}
.home-steps {padding-top: 80px;}
.home-get-started-tie-text{margin-right: 25px;font-size: 28px;}
.home-get-started-button {padding: 19px 25px;}
.footer-con{display: block;text-align: center;}
.footer-con span{margin-bottom: 10px;}
.footer-con li:first-child{margin-left: 0;}
.footer {padding: 26px 0;}

.get-help-popup.send_main_popup .dashboard-popup-inner { 
    padding: 30px 15px;
}

}

@media only screen and (max-width: 567px) {
  .btn{padding: 10px 15px 7px;}
  .login-inner {
    padding: 20px;
  }
  .login-copyright {
    flex-wrap: wrap;
    text-align: center;
  }
  .login-copyright span:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .login-upper-section {
    padding: 30px 0;
  }
  .login-down-section {
    padding-bottom: 30px;
  }
  .login-image {
    max-width: unset;
  }
  .login-form-register-link i {
    font-size: 20px;
  }
  .login-form-inner .btn {
    margin-bottom: 25px;
  }
  .login-title {
    font-size: 20px;
  }
  .login-form-login-link {
    font-size: 14px;
  }
  .login-form-inner .form-group,
  .login-form-inner .form-check {
    margin-bottom: 15px;
  }
  .login-form-inner .form-label {
    font-size: 14px;
  }
  /* dashboard */
  .dashboard-button-box {
    margin-right: 0;
  }
  .notificationboxMain {
    margin-right: 7px;
  }
  .dashboard-navbar-search {
    display: none;
  }
  .dashboard-featured .dashboard-card.total-appraisals-added-card {
    height: unset;
    margin-bottom: 20px;
  }
  .total-appraisals-button-wrap {
    margin-top: 0;
    position: unset;
    bottom: unset;
    left: unset;
    text-align: left;
  }
    .dashboard-featured .total-appraisals-button-wrap {margin-top: 0;
    position: unset;
    bottom: unset;
    left: unset;
    text-align: left;}
    .dashboard-featured .dashboard-card.total-appraisals-added-card {height: unset;padding-bottom: 20px;}
  .total-appraisals-added-card {
    padding-bottom: 20px;
  }

  .appraisal-head-wrap{padding: 0 15px;}
  .notifications-head-wrap.appraisal-head-wrap{padding: 0 0px;}
  .appraisal-head-title{margin-bottom: 0px;}
  .appraisal-head-buttons{margin-bottom: 15px;}
  .appraisal-head-wrap .page-title{font-size: 18px;}
  .appraisal-tabs .tab-pane{padding: 20px 15px;}
  .appraisal-inner.dashboard-inner{padding: 20px 0px;}
  .card-title{font-size: 16px;}
  .upload-button{min-width: 130px;font-size: 14px;}
  .upload-button .icon{font-size: 13px;}
  .appraisal-form-inner .col-form-label{font-size: 14px;}
  .appraisal-form-inner .form-group{padding: 9px 15px;}
  .appraisal-form-inner .form-control{padding: 4px 0;}
  .appraisal-tabs .nav-pills .nav-link{padding: 12px 15px;}
  .appraisal-form-inner .form-select{font-size: 14px;}
  .manager-details-user{margin-bottom: 10px;}
  .manager-details-inner{margin-bottom: 25px;margin-bottom: 25px;}
  .m-100{width: 100%;flex: 100%;}
  .manager-image{flex: 0 0 45px;margin-right: 10px;}
  .manager-image i {width: 16px;height: 16px;bottom: 2px;right: -2px;font-size: 9px;}
  .manager-image img{width: 40px;height: 40px;}
  .manager-info h4{font-size: 16px;}
  .manager-info span{font-size: 14px;}
  .history-notescol{width: 100%;}
  .historynotecol{margin-bottom: 10px;width: auto;}
  .historynotecol:last-child{margin-bottom: 0;}
  .history-notes-inner .offer-note-info p{font-size: 14px;}
  .history-notes-inner .offer-note-info span{font-size: 14px;}
  .offer-details-inner .form-group .form-label{font-size: 14px;}
  .arrow-icon{display: block;margin: auto;transform: rotate(270deg);}
  .mw-162{min-width: 100%;}
  .mw-168{min-width: 100%;}
  .mw-153{min-width: 100%;}
  .w-442{width: 100%;}
  .history-notes-inner .form-group ul li{width: 100%;margin-bottom: 10px;font-size: 14px;}
  .history-notes-inner .form-group ul li ~ li{margin-bottom: 10px;}
  .history-note-detail.offer-details-inner .form-group .form-label{margin-bottom: 10px;}
  .mw-100{width: 100%;}
  .dashboard-latest-appraisals .dashboard-navbar-search .form-control{width: 100%;}
  .helpyouboxcol{margin-bottom: 10px;}
  .helpyouboxcol:last-child{margin-bottom: 0px;}
  .contactimg{margin: 0;}
  .contactMian{margin-bottom: 20px;padding: 0 15px;}
  .contactdetails{padding: 0 15px;}
  .contactinforow .contactinfocol:last-child{width: 100%;}
  .contactinforow .contactinfocol:first-child{width: 100%;margin-bottom: 20px;}
  .contact-inner{padding: 20px 15px;}
  .dashboardml{margin-bottom: 10px;}
  .dashboardml ~ .dashboardml{margin-left: 1px;}
  .notification.edit-profile{border-radius: 0;}
  .notification{border-radius: 0;}
  .login-inner.add-new-user-inner{border-radius: 0;padding: 20px 15px;}
  .add-new-user-inner .login-head{padding-bottom: 15px;margin-bottom: 15px;}
  .login-title{margin-bottom: 15px;}
  .tel-country-list{font-size: 14px;}
  .inputField{font-size: 14px;width: calc(100% - 99px);}
  .intl-tel-input{padding: 0 5px 0 0;margin-right: 5px;}
  .notice{margin-bottom: 15px;}
  .dashboardml ~ .dashboardml{margin-left: 5px;}
  .dashboard-notification .offer-note-info p span{font-size: 16px;}
  .dashboard-notification .offer-note-info p{font-size: 16px;}
  .login-text{font-size: 14px;}
  .user-reset-password-section .login-copyright span:first-child{margin-bottom: 0;}
  .login-inner.add-new-appraisal-inner{padding: 20px 15px;border-radius: 0;}
  .form-privacy-policy br{display: none;}
  .newcard-submitcol{width: 100%;flex: 0 0 100%;margin-bottom: 15px;}
  .newcard-submitcol:last-child{margin-bottom: 0px;}
  .new-appraisal-card-submit .form-privacy-policy{margin-top: 20px;}
  .add-new-appraisal-inner .vehicle-photos{padding: 0;}
  .show-earningsMain{border-radius: 0;}
  .myprofilecol{margin-bottom: 15px;}
  .myprofilecol:last-child{margin-bottom: 0;}
  .get-help-popup.contact-popup .dashboard-popup-inner{padding-left: 0;padding-right: 0;}
  .contactinfoMian .contact-inner{border-radius: 0;}
  .appraisal-tabs .tab-pane{border-bottom-right-radius: 0;border-bottom-left-radius: 0;}
  .managercol{margin-bottom: 15px;}
  .managercol:last-child{margin-bottom: 0px;}
  .edit-profile .manager-details-inner{margin-bottom: 0;}
  .dashboard-card.dashboard-notification{padding: 20px 15px;border-radius: 16px;}
  .dashboard-card-icon{font-size: 14px;flex: 0 0 20px;max-width: 20px;height: 20px;}
  .login-image .dashboard-card{min-width: 140px;}
  .dashboard-card-label{font-size: 12px;}
  .dashboard-card{padding: 10px;}
  .login-section .total-appraisals-added-card,.home-banner-section .total-appraisals-added-card{padding: 10px;}
  .login-image .total-appraisals-count{font-size: 20px;}
  .login-image .total-appraisals-text{font-size: 14px;}
  .login-image .total-appraisals-added-card p{font-size: 12px;}
  .login-section .total-appraisals-button-wrap .btn,.home-banner-section .total-appraisals-button-wrap .btn{font-size: 12px;}
  .login-image .total-appraisals-added-card{width: 145px;}
  .login-image .dashboard-card.is-total-won{left: 0;}
  .login-image .dashboard-card.is-total-appraisals{left: 30px;top: -30px;}
  .login-image .total-appraisals-added-card{top: -30px;}
  .login-section .login-inner{margin-bottom: 70px;}
  .react-daterange-picker__button {padding: 5px 12px;}
  .edit-page .appraisal-head-buttons {margin-bottom: 0;}
  .edit-page .appraisal-head-wrap {padding: 10px 20px;}
  .edit-page .appraisal-head-buttons .btn-outline-secondary{display: none;}
  .edit-page .appraisal-head-buttons .btn-outline-primary{font-size: 14px;margin-left: 0;}
  .edit-page .appraisal-head-buttons .btn i{margin-right: 5px;}
  .is-sticky .appraisal-head-wrap.edit-page{padding-left: 15px;padding-right: 15px;}
  .is-sticky .appraisal-head-wrap.edit-page .page-title{font-size: 18px;}
  .dashboard-navbar {padding: 12px 15px;}
  .dashboard-latest-appraisals .dashboard-navbar-search {max-width: unset;width: 100%;}
  .react-daterange-picker__calendar {min-width: 270px;}
  .contactimg{display: none;}
  .offer-details-inner .form-group{margin-bottom: 14px;}
  .w-522{width: auto;}
  .top-employee-user,.top-employee-count{margin-bottom: 15px;}
  .top-employee-popup .btn-outline-grey{padding-right: 35px;padding-bottom: 8px;}
  .login-logo img {max-width: 70%;margin-left: auto;margin-right: auto;}
  .login-section.with-bg .login-inner{margin-bottom: 0;}
  .dashboard-export-sticky{position: absolute;right: 0;top: -63px;}
  .faqMian iframe {max-height: 170px !important;}
  .home-banner-section {padding: 70px 0 0;}
  .sec-title {font-size: 36px;}
  .header-buttons .btn-pink{margin-left: 0;position: fixed;bottom: 0;left: 0;right: 0;width: 100%;border-radius: 0;z-index: 1;}
  .header .btn {padding: 14px 28px 10px;}
  .header {padding: 16px 0;}
  .home-banner-buttons .btn {padding: 20px 31px 17px;}
  .home-banner-section{background-image: none;padding-bottom: 50px;}
  .home-steps-con .sec-subtitle{font-size: 40px;}
  .home-steps-con .sec-title {font-size: 30px;}
  .home-steps-con p{font-size: 20px;}
  .home-get-started-title {font-size: 30px;line-height: 1.2;margin-bottom: 25px;}
  .home-get-started-tie{flex-wrap: wrap;justify-content: center;text-align: center;border-radius: 30px;background: linear-gradient(90deg, rgba(123, 178, 99, 0.6) 50%, rgb(55 88 114 / 100%) 81.04%);width: 100%;}
  .home-get-started-tie-text{margin-right: 0;font-size: 24px;margin-bottom: 15px;flex: 0 0 100%;}
  .home-get-started-call-us-sec{justify-content: center;}
  .home-get-started-call-us-sec span{font-size: 22px;}
  .home-get-started-call-us-sec h4{font-size: 18px;}
  .home-get-started-button {padding: 15px 20px;font-size: 18px;}
  .home-get-started-inner {padding: 25px 5px;background-position: 24%;}
  .footer{padding-bottom: 60px;}
  .footer-con li {margin-left: 15px;}
  .footer-con li a {font-size: 14px;}
  .home-get-started {padding-left: 20px;padding-right: 20px;}
  .faq-accordionMian{max-height: unset;}
  .home-steps .row, .home-steps .container{--bs-gutter-x: 20px;}
  .offer-notes-inner{max-height: unset;}
  .form-check-label {font-size: 15px;}
  .form-check .form-check-input {margin-left: -25px;}
  .form-check-input {width: 18px;height: 18px;margin-top: 3px;}
  .form-check {padding-left: 25px;}
  .subscription-monthly-price {font-size: 16px;margin-bottom: 8px;}
  .subscription-ending-after {padding: 6px 10px;margin-top: 8px;}
  .dashboard-dealer-details{margin-bottom: 20px;}
  .dashboard-dealer-details-label, .dashboard-dealer-details-text {font-size: 16px;}

  .dashboard-export-sticky.reports-page {top: -45px;}
  .confirm-popup-inr {padding: 18px;width: 80vw;}
  .dashboard-need-vehicle-list{max-height: unset;}
}

@media only screen and (max-width: 375px) {
  .login-form-register-link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 350px) {
  .pagination{margin-top: 15px;}
  .pagination .nav-links li a{margin-bottom: 5px;}
  .dashboard-navbar{padding: 12px 15px;}
  .dashboard-button-box{margin-right: 12px;}
  .login-image .dashboard-card.is-total-appraisals{left: 0;}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

@media only screen and (min-width: 568px) and (max-width: 991px) {
}
