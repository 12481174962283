.cmsCon {
  display: block;
  width: 100%;
  color: #000;
}
.cmsCon p {
  display: block;
  margin-bottom: 50px;
  color: #000;
  line-height: 1.4;
  font-weight: 400;
  font-size: 16px;
}
.cmsCon p a {
  text-transform: initial;
}
.cmsCon ul {
  list-style: none;
  font-size: 0;
  margin-bottom: 3rem; /*display: grid;*/
}
.cmsCon ul li {
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}
.cmsCon ul li:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0.6rem;
  background: #000;
}
.cmsCon ul li:last-child {
  margin-bottom: 0;
}
.cmsCon ul li ul li {
  padding-left: 16px;
}
.cmsCon ul li ul li:before {
  width: 8px;
  height: 2px;
  border-radius: 0;
  top: 10px;
}

.cmsCon h1 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 900;
  color: #000;
  display: block;
  margin-bottom: 20px;
  padding-top: 0rem;
  text-transform: uppercase;
}
.cmsCon h2 {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 900;
  color: #000;
  display: block;
  margin-bottom: 18px;
  padding-top: 0rem;
  text-transform: uppercase;
}
.cmsCon h3 {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 900;
  color: #000;
  display: block;
  margin-bottom: 16px;
  padding-top: 0rem;
  text-transform: uppercase;
}
.cmsCon h4 {
  font-size: 28px;
  line-height: 1.2;
  font-weight: 900;
  color: #000;
  display: block;
  margin-bottom: 14px;
  padding-top: 0rem;
  text-transform: uppercase;
}
.cmsCon h5 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 900;
  color: #000;
  display: block;
  margin-bottom: 14px;
  padding-top: 0rem;
  text-transform: uppercase;
}
.cmsCon h6 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 900;
  color: #000;
  display: block;
  margin-bottom: 12px;
  padding-top: 0rem;
  text-transform: uppercase;
}
.cmsCon iframe {
  width: 100%;
  height: 500px;
}

.cmsCon .alignleft {
  float: left;
  width: auto;
  max-width: 50%;
  margin: 0 50px 50px 0;
}

.cmsCon .alignright {
  float: right;
  width: auto;
  max-width: 50%;
  margin: 0 0 50px 50px;
}

.cmsCon .aligncenter {
  width: auto;
  max-width: 100%;
  margin: 0 auto 50px auto;
  text-align: center;
}
.cmsCon .aligncenter img {
  margin: auto;
}

.cmsCon ol {
  padding: 0 0;
  display: inline-block;
  counter-reset: cmscon-counter;
  list-style-type: none;
}
.cmsCon ol li {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  list-style: none;
  margin-bottom: 15px;
  padding-left: 0;
}
.cmsCon ol li:before {
  counter-increment: cmscon-counter;
  content: "" counter(cmscon-counter) ".";
  line-height: 1.4;
}
.cmsCon ol li ul {
  margin-top: 30px;
}
.cmsCon ol li ul li {
  padding-left: 25px;
  position: relative;
}
.cmsCon ol li ul li:before {
  counter-increment: none;
  content: no-close-quote;
}

.cmsCon ul.half li {
  width: 50%;
  display: inline-block;
}

.cmsCon b {
  margin-bottom: 0;
  display: inline;
  font-size: 16px;
  margin-bottom: 15px;
  display: inline-block;
}
.cmsCon p b {
  margin-bottom: 0;
}
.cmsCon a {
  transition: color 0.4s ease-in-out;
  color: #ef5166;
}
.cmsCon a:hover {
  color: #d8384d;
}
.cmsCon p a {
  transition: color 0.4s ease-in-out;
  color: #ef5166;
}
.cmsCon p a:hover {
  color: #000;
}
.cmsCon p a.btn:hover {
  color: #000;
}
.clearfix {
  clear: both;
}
@media only screen and (max-width: 992px) {
  .cmsWrap {
    padding: 50px 0;
  }
  .cmsCon h1 {
    font-size: 30px;
    margin-bottom: 18px;
  }
  .cmsCon h2 {
    font-size: 34px;
    margin-bottom: 16px;
  }
  .cmsCon h3 {
    font-size: 32px;
    margin-bottom: 14px;
  }
  .cmsCon h4 {
    font-size: 26px;
    margin-bottom: 12px;
  }
  .cmsCon h5 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .cmsCon h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .cmsCon iframe {
    width: 100%;
    height: 350px;
  }
  .cmsCon p {
    margin-bottom: 30px;
  }
}
