@font-face {
  font-family: "icon-pack-v1";
  src: url("../fonts/icon-pack-v1.eot?j79q1j");
  src: url("../fonts/icon-pack-v1.eot?j79q1j#iefix") format("embedded-opentype"),
    url("../fonts/icon-pack-v1.ttf?j79q1j") format("truetype"),
    url("../fonts/icon-pack-v1.woff?j79q1j") format("woff"),
    url("../fonts/icon-pack-v1.svg?j79q1j#icon-pack-v1") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-pack-v1" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mostaccepted:before {
  content: "\e951";
}
.icon-barcode:before {
  content: "\e950";
}
.icon-todayappraisals:before {
  content: "\e94c";
}
.icon-pdf:before {
  content: "\e94f";
}
.icon-accepteddeal:before {
  content: "\e947";
}
.icon-counterofferrequsetchat:before {
  content: "\e948";
}
.icon-offermade:before {
  content: "\e949";
}
.icon-appraised:before {
  content: "\e94a";
}
.icon-totalappraisals:before {
  content: "\e94b";
}
.icon-nodeal:before {
  content: "\e94d";
}
.icon-purchase:before {
  content: "\e94e";
}
.icon-reset:before {
  content: "\e946";
}
.icon-money:before {
  content: "\e943";
}
.icon-invoice:before {
  content: "\e945";
}
.icon-expiry:before {
  content: "\e944";
}
.icon-mail:before {
  content: "\e916";
}
.icon-account:before {
  content: "\e930";
}
.icon-arrow-down-white:before {
  content: "\e931";
}
.icon-call:before {
  content: "\e932";
}
.icon-check:before {
  content: "\e933";
}
.icon-chevron-left:before {
  content: "\e934";
}
.icon-chevron-right:before {
  content: "\e935";
}
.icon-down-arrow:before {
  content: "\e936";
}
.icon-getting-started:before {
  content: "\e937";
}
.icon-imgzoom:before {
  content: "\e938";
}
.icon-map:before {
  content: "\e939";
}
.icon-password2:before {
  content: "\e93a";
}
.icon-payment:before {
  content: "\e93b";
}
.icon-phone-line:before {
  content: "\e93c";
}
.icon-twitter:before {
  content: "\e93d";
}
.icon-work:before {
  content: "\e93e";
}
.icon-youtube:before {
  content: "\e93f";
}
.icon-paypal:before {
  content: "\e940";
}
.icon-credit-card:before {
  content: "\e941";
}
.icon-email:before {
  content: "\e942";
}
.icon-eye-open:before {
  content: "\e92f";
}
.icon-chevron-down-thin:before {
  content: "\e90d";
}
.icon-calender:before {
  content: "\e900";
}
.icon-edit:before {
  content: "\e901";
}
.icon-save:before {
  content: "\e92e";
}
.icon-dollar:before {
  content: "\e92d";
}
.icon-close:before {
  content: "\e92c";
}
.icon-chevron-down:before {
  content: "\e90b";
}
.icon-add:before {
  content: "\e902";
}
.icon-mail-2:before {
  content: "\e92a";
}
.icon-phone:before {
  content: "\e92b";
}
.icon-product:before {
  content: "\e929";
}
.icon-eye:before {
  content: "\e928";
}
.icon-notification-2:before {
  content: "\e903";
}
.icon-add-image:before {
  content: "\e904";
}
.icon-add-picture:before {
  content: "\e905";
}
.icon-add-user:before {
  content: "\e906";
}
.icon-appraisals:before {
  content: "\e907";
}
.icon-arrow-left:before {
  content: "\e908";
}
.icon-arrow-right:before {
  content: "\e909";
}
.icon-back-aero:before {
  content: "\e90a";
}
.icon-chat:before {
  content: "\e90c";
}
.icon-dashboard:before {
  content: "\e90e";
}
.icon-delete:before {
  content: "\e90f";
}
.icon-delete-2:before {
  content: "\e910";
}
.icon-earnings:before {
  content: "\e911";
}
.icon-employees:before {
  content: "\e912";
}
.icon-export:before {
  content: "\e913";
}
.icon-filter:before {
  content: "\e914";
}
.icon-logout:before {
  content: "\e915";
}
.icon-more:before {
  content: "\e917";
}
.icon-more-table:before {
  content: "\e918";
}
.icon-new-appraisals:before {
  content: "\e919";
}
.icon-no-deals:before {
  content: "\e91a";
}
.icon-notification:before {
  content: "\e91b";
}
.icon-offer-made:before {
  content: "\e91c";
}
.icon-password:before {
  content: "\e91d";
}
.icon-password-2:before {
  content: "\e91e";
}
.icon-reports:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e920";
}
.icon-send:before {
  content: "\e921";
}
.icon-settings:before {
  content: "\e922";
}
.icon-total-appraisal:before {
  content: "\e923";
}
.icon-upload:before {
  content: "\e924";
}
.icon-url:before {
  content: "\e925";
}
.icon-won:before {
  content: "\e926";
}
.icon-working:before {
  content: "\e927";
}
